<template>
    <div class="content-box">
        <div class="alert alert-warning">
            <BootstrapIcon icon="exclamation" size="2x"/>
            {{ $t('messages.holidayRequestWarning') }}
        </div>
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        v-if="'id' in holiday && !isOwner()"
                        class="row"
                        :label="$t('forms.status')"
                        label-for="h-status"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-status"
                            v-model="statusSelect"
                            :options="statuses"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.holiday.purpose.$errors.length }"
                        :label="$t('forms.purpose')"
                        label-for="purpose"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea
                            v-model="holiday.purpose"
                            id="purpose"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.holiday.purpose.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.holiday.fromDate.$errors.length }"
                        :label="$t('forms.fromDate')"
                        label-for="h-fromdate"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <datepicker
                            id="fromDate"
                            v-model="holiday.fromDate"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.holiday.fromDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.holiday.toDate.$errors.length }"
                        :label="$t('forms.toDate')"
                        label-for="h-todate"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <datepicker
                            id="toDate"
                            v-model="holiday.toDate"
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.holiday.toDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";
import {getUserData} from "@/auth/utils";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        holidayObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList("holiday_request_status", this.statuses);
        if ('id' in this.holiday) {
            this.statusSelect = {
                label: this.$helper.getEnumTranslation('holiday_request_status', this.holiday.status, this.$i18n.locale),
                id: this.holiday.status
            }
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            holiday: {
                purpose: {required},
                fromDate: {required},
                toDate: {required},
            },
        };
    },
    data() {
        return {
            holiday: this.holidayObject,
            statuses: [],
            statusSelect: {}
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.holiday.fromDate) {
                    this.holiday.fromDate = this.$helper.formatDateTimeToSend(this.holiday.fromDate)
                }
                if (this.holiday.toDate) {
                    this.holiday.toDate = this.$helper.formatDateTimeToSend(this.holiday.toDate)
                }
                if (this.action === "add") {
                    this.create(
                        this.$HolidayRequests,
                        this.holiday,
                        this.$t("messages.holidayCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.holiday.status = this.statusSelect.id;
                    this.$emit("clearAction")
                    this.update(
                        this.$HolidayRequests,
                        this.holiday.id,
                        this.holiday,
                        this.$t("messages.holidayUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        isOwner() {
            return "employee" in this.holiday &&
                getUserData().id === this.holiday.employee.id;
        },
        success() {
            this.$router.push(`/holiday-requests`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

